<template>

  <div class="row" ref="panelForm">

    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-12 col-sm-12">

                <div class="row">
                  <div class="col-md-12">
                     <ValidationProvider
                      vid="forum_id"
                      rules="required"
                      name="The Edition"
                      v-slot="{ passed, failed,errors }">
                      <fg-select
                        name="forum_id"
                        size="large"
                        filterable
                        clearable
                        placeholder="Edition"
                        :error="failed ? errors[0]: null"
                        :input-classes="'select-default'"
                        :label="'Edition'"
                        :list="forumList"
                        :listItemLabel="'name'"
                        :listItemValue="'id'"
                        v-model="formData.forum_id">
                      </fg-select>
                    </ValidationProvider>
                  </div>
                </div>

               <div class="row">
                 <div class="col-md-12">
                   <ValidationProvider
                           vid="title"
                           rules="required"
                           name="The Title"
                           v-slot="{ passed, failed,errors }">
                     <fg-input type="text"
                               :error="failed ? errors[0]: null"
                               label="Title"
                               name="title"
                               fou
                               v-model="formData.title">
                     </fg-input>
                   </ValidationProvider>
                 </div>


               </div>
               <div class="row">
                 <div class="col-md-12">
                   <ValidationProvider
                           vid="description"
                           rules=""
                           name="The Description"
                           v-slot="{ passed, failed,errors }">
                  <fg-text type="text"
                          label="Description"
                          name="description"
                          fou
                          :rows="5"
                          v-model="formData.description">
                  </fg-text>
                   </ValidationProvider>
                 </div>
               </div>


              </div>


            </div>

          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/program-overview/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>

      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Select, Option,Tooltip} from 'element-ui';
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {ImageUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import {mapGetters} from "vuex";
import {PrimeUploader} from '@/components';
import {PrimeVideoUploader} from '@/components';


extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    FgSelect,
    LSwitch,
    PrimeUploader,
    ImageUploader,
    PrimeVideoUploader,
    'editor': Editor
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,
      editorConfig: this.getEditorConfig(),

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,

      formData: {
        title: "",
        description: "",
        forum_id: "",

      },

      forumList: [],

    };
  },

  computed: {
    ...mapGetters({
      mediaInfo: "global/mediaInfo"
    })
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.panelForm
    });

    this.axios.post("program-overview/builder").then((response) => {
      this.forumList = response.data.forums;

      this.id = this.$route.params['id'];

      if (this.id !== undefined) {
        this.editMode = true;
        this.formTitle = "Edit Program Overview Item";
        this.getPanel();
      } else {
        this.editMode = false;
        this.formTitle = "Add Program Overview Item";
        this.formData.forum_id = response.data.currentForum ? response.data.currentForum.id : '';
        this.loader.hide();
      }



    }).catch((error) => {
      console.error(error);
    })
  },


  methods: {
    getPanel() {
      let data = {
        'id' : this.id,
      };
      this.axios.post("program-overview/get",data).then((response) => {
        this.formData = response.data;
        this.vueDate();
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Item Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },


    submit() {
      let request;
      let successMessage;
      this.submitting = true;

      this.laravelDate();
      if (this.editMode === true) {
        request = this.axios.put("program-overview/update/" + this.id, this.formData);
        successMessage = "Item Updated Successfully";
      } else {
        request = this.axios.post("program-overview/create", this.formData);
        successMessage = "Item Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/program-overview/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    },




  }
}
</script>
